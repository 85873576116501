<template>
  <div class="container pd col-start-center">
    <headBar
      :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
      left-arrow
      @click-left="newAppBack"
    />
    <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png" alt="">
    <div class="check-content">
      <h2 class="title f36">开始运动的初衷是什么？</h2>
      <ul class="check-ul f28">
        <li :class="['check-item--single', 'row-center-center', checkedList.includes(item.code) ? 'checked fw6' : '']" v-for="item in list" :key="item.code" @click="choose(item.code)">{{item.title}}</li>
      </ul>
    </div>
    <div class="button-box fixed">
      <div class="cl-button f32 fw5 row-center-center" @click="goNext">下一步（ 3/7 ）</div>
    </div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      list: [],

      checkedList: [],
    }
  },
  computed: {},
  methods: {
    newAppBack,
    choose(code) {
      // 单选
      this.checkedList = []
      this.checkedList.push(code)
    },
    goNext() {
      if (this.checkedList.length === 0) {
        this.$toast({
          message: '请选择',
          duration: 3000
        });
        return
      }
      this.$store.commit('setFlagJson', {sportOriginal: this.checkedList})
      this.$router.push('page-4')
      this.countPoint('43','311','1477')
    }
  },
  async created() {
    await this.$getAllInfo()
    let obj = await getList(this.userId, this.token, 'sportOriginal')

    this.checkedList = obj.codes
    this.list = obj.list

    this.countPoint('43','311','1476')
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.container {
  background-color: #F9FAFB;
}
</style>
